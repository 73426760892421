/* @import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities"; */
@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Sora:wght@300;400&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Sora", sans-serif;
}

button:focus {
  outline: none;
}

button.disabled {
  cursor: not-allowed;
  background-color: #91bd21c9;
}

nav > a > div {
  width: 0;
  transition: width 0.5s;
}

.wt {
  width: 600% !important;
}

.collapse-container h3 {
  font-size: 30px;
  font-weight: 400;
}

.collapse-container p {
  font-size: 20px;
  font-weight: 300;
}

.panel-open {
  margin: 0.75rem 1.5rem 0.5rem 1.5rem;
  transition-timing-function: ease-out;
  transition: all 600ms;
}

.panel-close {
  box-sizing: border-box;
  opacity: 0;
  width: 100%;
  height: 0;
  overflow: hidden;
  transition-timing-function: ease-in;
  transition: all 500ms;
}

/* 'Medio Ambiente' view styles */
.data-card h1 {
  font-size: 30px;
  font-weight: bold;
}

.data-card h4 {
  font-size: 20px;
  font-weight: 400;
}

.data-card span {
  font-size: 15px;
  font-weight: 400;
}

.bgApp {
  background-color: #F6F6F6;
}

/* 'Firmar' view styles */
.approve {
  background-color: #92bd21;
  color: white;
}

.approve.sign-button {
  width: 100%;
  height: 100%;
  font-size: 35px;
  border-radius: 20px;
}

.approve.signature {
  border-radius: 10px;
  font-size: 20px;
  width: 100%;
  height: 100%;
}

.approve.toContract {
  border-radius: 10px;
  font-size: 20px;
  width: 100%;
  height: 100%;
}

.sign p {
  color: #737373;
  font-size: 18px;
}

.sign span {
  color: #92bd21;
  font-weight: 600;
  cursor: pointer;
}

.term {
  font-size: 32px;
  color: #92bd21;
  font-weight: 600;
}

/* //animation */
section {
  opacity: 1;
}

/* Table styles */
table {
  position: relative;
  table-layout: fixed;
  width: 90%;
  font-family: "Sora", sans-serif;
  text-align: center;
}

thead tr:not(.leasing-row) {
  color: #fff;
  font-size: 20px;
  height: 40px;
}

thead tr th {
  position: sticky;
  top: 0;
  z-index: 1;
}

thead tr th:not(th.leasing) {
  position: sticky;
  top: 0;
  background-color: #4fac2e;
  height: 40px;
  width: 200px;
  font-weight: 400;
  text-align: center;
  /* z-index: 1; */
}

thead tr th.leasing {
  width: auto;
}

thead th:first-child {
  left: 0;
  z-index: 10;
}

tbody tr td:first-child {
  position: sticky;
  left: 0;
  z-index: 9;
}

thead tr th:first-child {
  left: 0;
  z-index: 10;
}

tbody tr {
  height: 40px;
  color: #737373;
}

th.leasing:first-child {
  width: 100px !important;
}

/* Header */
tr:first-child th:first-child {
  border-top-left-radius: 10px;
}
tr:first-child th:last-child {
  border-top-right-radius: 10px;
}
tr:last-child th:first-child {
  border-bottom-left-radius: 10px;
}
tr:last-child th:last-child {
  border-bottom-right-radius: 10px;
}

/* Body */
tbody tr td {
  background-color: #fff;
}

tr:nth-child(even) td {
  background-color: #f5f5f5;
}

tr td:first-child {
  border-radius: 10px 0 0 10px;
}

tr td:last-child {
  border-radius: 0 10px 10px 0;
}

/*calculation data table styles*/
#calculation-data-table {
}

/* End of Table styles */

.investment h2 {
  font-size: 20px;
  color: #737373;
}

#total h2 {
  color: #4fac2e;
  font-weight: 600;
}

/* Panel card styles */
#pie-chart text {
  font-size: 14px !important;
}

.solar-energy {
  font-size: 36px;
  font-weight: bold;
  color: #737373;
}

.panel-card-container {
  background-image: url("../files/images/solar_panel.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
}

.panel-card {
  background-color: #1a5e02;
  opacity: 0.6;
  filter: brightness(2.2);
}

.panel-card-info {
  color: #fff;
  position: relative;
  top: -32rem;
}

.panel-card-info h1 {
  font-size: 30px;
  font-weight: bold;
}

.panel-card-info h4 {
  font-size: 20px;
  font-weight: 400;
}

.documentation-button {
  background-color: #92bd21;
  font-size: 18px;
  border-radius: 8px;
  width: 100%;
  height: 100%;
}

/* 'Retorno de Inversion' view styles */
/* #returnInvestment h3 {
    color: #737373;
    font-weight: 600;
} */

.chart-container {
  min-width: 0;
}

/* Slider */
.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 4px;
  border-radius: 2px;
  outline: none;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background: #4fac2e;
  cursor: pointer;
}

/* Slider labels */
.sliderContainer h6 {
  font-size: 15px;
  color: #737373;
}

.sliderContainer p {
  font-size: 15px;
  color: #737373;
}

.sliderClousure{
  font-size: 14px;
  color: #737373;
}

/* Cashflow Modal */
.Modal-module_dialog__2UBIn {
  --st-dialog-maxWidth: 85rem;
}

/* Download Button */
.download-button {
  width: 100%;
  height: 3.1rem;
  font-size: 18px;
  border-radius: 8px;
  background-color: #92bd21;
  color: #fff;
}

.welcome {
  color: #4fac2e;
  font-weight: 600;
}

.message-modal p {
  color: #737373;
}

.sigCanvas {
  border: 2px solid #cdd3d9;
}

/* Footer */
.toTopButton {
  background-color: #92bd21;
  cursor: pointer;
}

/* Tech images */
.bgImage {
  background-size: contain;
}

/* Loader */
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader > svg {
  margin-bottom: 10rem;
}

.chart-loader {
  position: relative;
  height: 87%;
  background-color: #f8f8f84f;
}

/* Responsive */

@media (min-width: 768px) {
  /* CUSTOM SCROLL BAR */
  ::-webkit-scrollbar {
    width: 20px;
    height: 20px;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  ::-webkit-scrollbar-track:vertical {
    border-left: 1px solid #dee2e6;
  }

  ::-webkit-scrollbar-track:horizontal {
    border-top: 1px solid #dee2e6;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #cdd3d9;
    border-radius: 10px;
    border: 6px solid transparent;
    background-clip: content-box;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #acb7c0;
  }
}

@media (min-width: 1024px) {
  .cashflow-modal {
    --st-dialog-baseWidth: 83rem;
    height: 37.5rem;
  }

  .cashflow-modal > div {
    width: 100%;
    height: 100%;
  }

  .terms-conditions {
    --st-dialog-baseWidth: 48rem;
    height: 30rem;
  }

  .signature-modal {
    --st-dialog-baseWidth: 48rem;
    height: 34rem;
  }

  .message-modal {
    --st-dialog-baseWidth: 28rem;
    height: 20rem;
  }
}

@media (min-width: 1536px) {
  .panel-card-info {
    color: #fff;
    position: relative;
    top: -36rem;
  }

  .main-charts-container {
    height: 41rem;
  }

  .chart-container > div {
    width: 100%;
    height: 40rem !important;
  }

  .data-card h1 {
    font-size: 40px;
    font-weight: bold;
  }

  .data-card h4 {
    font-size: 28px;
    font-weight: 400;
  }

  .data-card span {
    font-size: 20px;
    font-weight: 400;
  }

  .chart-loader {
    top: -40.5rem;
  }
}

@media (min-width: 1024px) and (max-width: 1535px) {
  .chart-container > div {
    width: 100%;
    height: 29rem !important;
  }

  .table-container {
    height: 30rem;
  }

  .sliders-container {
    height: 17rem;
  }

  .table-container thead tr th {
    width: auto;
  }

  .chart-loader {
    top: -29.5rem;
  }
}

@media (min-width: 300px) and (max-width: 767px) {
  .chart-container > div {
    width: 100%;
    height: 38rem !important;
    margin-top: 1rem;
  }

  .cashflow-modal {
    --st-dialog-baseWidth: 23rem;
    height: 37.5rem;
  }

  .cashflow-modal > div {
    width: 100%;
    height: 100%;
  }

  .terms-conditions {
    --st-dialog-baseWidth: 23rem;
    height: 30rem;
  }

  .signature-modal {
    --st-dialog-baseWidth: 23rem;
    height: 34rem;
  }

  .message-modal {
    --st-dialog-baseWidth: 23rem;
    height: 20rem;
  }

  .chart-loader {
    top: -37.5rem;
  }

  .approve.sign-button {
    height: 60%;
    font-size: 20px;
    padding: 0 1em 0;
  }
}

span.swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #92bd21;
  opacity: 1;
}

.swiper-custom-pagination {
  position: relative;
  text-align: center;
  justify-content: center;
}
/* END OF CUSTOM CSS */

